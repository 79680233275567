import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeadersComponent } from './headers/headers.component';
import { NewsComponent } from './news/news.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SplashComponent } from './splash/splash.component';
import { LottieModule } from 'ngx-lottie';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MonitorComponent } from './monitor/monitor.component';
import { register } from 'swiper/element/bundle';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

register()


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeadersComponent,
    NewsComponent,
    SplashComponent,
    MonitorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    NgbModule,
    AngularFireAuthModule,
    NgxPaginationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzTableModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [{ provide: FIREBASE_OPTIONS, useValue: environment.firebase }, { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
  schemas:[NO_ERRORS_SCHEMA]
})
export class AppModule { }
