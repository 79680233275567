<app-headers></app-headers>
<div *ngIf="loader">
<app-splash></app-splash>
</div>
<div *ngIf="!loader" style="padding: 3%;padding-top: 5%;margin-bottom: 3%;">
    <div style="float: left;display: flex;margin-bottom: 3%;">
        <select class="form-control" style="margin-left: 5%;margin-right: 5%;" [(ngModel)]="filterOption" >
            <option value="">Please Select</option>
            <option value="title">Title</option>
            <option value="domain">Domain Name</option>
            <option value="date">Date</option>
        </select>
        <input class="form-control" *ngIf="filterOption=='title'" [(ngModel)]="searchByName" placeholder="Search By Title" (keydown)="searchByName_()" (keyup)="searchByName_()"/>
        <select class="form-control" [(ngModel)]="domainType" *ngIf="filterOption=='domain'" (change)="OnSelectDomain()">
            <option value="" selected>Please Select</option>
            <option *ngFor="let domain of domain_names" [value]="domain" >{{domain}}</option>
        </select>
        <input class="form-control" type="date" [(ngModel)]="filterdate" *ngIf="filterOption=='date'" (change)="OnchangeDate()" />
    </div>
    <button class="form-control" (click)="CreateNews()" style="background: rgba(24, 24, 180, 0.603);
    color: white;
    width: 15%;
    float: right;">Create</button>

    <table>
        <tr>
          <th>id</th>
          <th>Title</th>
          <th>Source</th>
          <th>url</th>
          <th>Published On</th>
          <th>Summary</th>
          <th>Banner</th>
          <th>Action</th>
        </tr>
        <tr *ngFor="let news of newsList | paginate:{itemsPerPage:10,currentPage:p};let i=index">
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">
            <!-- <span class="badge badge-custom" *ngIf="news.url.split('/')[2]=='blockpanda.co'">
                <img style="width:100%"  src="../../assets/pandas.png"/>
            </span> -->
            {{news.id}}
        </td>
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">{{news.title}}</td>
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">{{news.source_domain}}</td>
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''" style="width: 10%;"><a style="text-decoration: underline;color: blue;cursor: pointer;" [href]="news.url" target="_blank">{{news.url}}</a></td>
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">
            <img *ngIf="isToday(news.date_publish)" src="../../assets/rocket.png"/>
            {{news.date_publish | date}}</td>
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">{{news.summary}}</td>
          <!-- <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">{{news.category}}</td> -->
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">  <img *ngIf="news.image_url!=''" alt="pandas" [src]="news.image_url" style="width:70%"/>
            <img *ngIf="news.image_url==''"  src="../../assets/pandas.png" style="width:70%"/>
        </td>
          
          <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">
            <a  data-toggle="modal" data-target="#exampleModal" (click)="showEdit(news)"><img src="../../assets/fullscreen.png" style="width: 30%;cursor: pointer;margin-right: 3%;"/></a>
            <!-- <img src="../../assets/delete.png" (click)="showDelete(news)" style="width:25%;cursor: pointer;"/> -->
            <a [href]="appUrl+news.slug" target="_blank"><img src="../../assets/view.png" style="width: 50%;cursor: pointer;"/></a>
          </td>
        </tr>
        <pagination-controls style="position: relative;top:50px;display: table-caption;" (pageChange)="p = $event" nextLabel="" previousLabel=""></pagination-controls>
      </table>
    
      
</div>
    <div id="myModal" *ngIf="showModalEdit" class="modal">
        <div class="modal-content">
          <span class="close" (click)="closeModal()">&times;</span>
          <!-- <p>Some text in the Modal..</p> -->
          <div class="row">
        <div>
            <div>
            <label style="margin-left: 4%;">
                Title</label>
                <input class="form-control" style="margin-left: 3%;width: 80%;" [disabled]="!enableEdit" [(ngModel)]="editData.title"/>

            <br>
            <!-- <label style="margin-left:3% ;">
                Image</label>
                <img style="width: 20%;margin-left: 4%;" [src]="editData.image_url" class="form-control"/>
       -->
       <div>
        <!-- <label style="margin-left: 4%;">
            Title</label>
            <input class="form-control" style="margin-left: 3%;width: 80%;"  [(ngModel)]="editData.title"/> -->

        <br>
        <label style="margin-left:3% ;">Upload Banner Image</label>
            <!-- <input *ngIf="editData.image_url=='' || editData.image_url==undefined"type="file" class="form-control"  style="margin-left: 3%;width: 80%;"  
            (change)="onuploadImage($event)"
            /> -->
                            <!-- [(ngModel)]="editData.image_url" -->
            <span style="color: green;font-weight: bold;">{{imageupload_progress}}</span>
            <div *ngIf="editData.image_url!='' || editData.image_url==undefined" style="display: flex;width: min-content;flex-direction: row-reverse;position: relative;left: 29px;">
                <span *ngIf="enableEdit" style="cursor: pointer;
                position: relative;
                left: 5%;
                top: 6px;
                
                " (click)="removeImage()">X</span>
            <img [src]="editData.image_url" style="width: 300px;margin-top: 5%;" />
        </div>

            <!-- <img *ngIf="!enableEdit" style="width: 20%;margin-left: 4%;" [src]="editData.image_url" class="form-control"/> -->
  
    </div>
        </div>
        <div style="    margin-top: 4%;
        position: relative;
        right: 7%;
        margin-bottom: 3%;">
        <div style="    display: flex;
        justify-content: space-around;
        margin-left: 0;
        padding-left: 0;
        position: relative;
        margin-bottom: 3%;">
            <label>
                Sentiment
                <input disabled="true" type="text" class="form-control" [(ngModel)]="editData.sentiment"/>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label>
                Source domain:
                <br>
                <span style="font-weight: bold;text-decoration: underline;color:blue">{{editData.source_domain}}</span>
            </label>
        </div>
        <div style="    display: flex;
        justify-content: space-around;">
            <label>
               <span style="font-weight: bold;">Is Sponsored?</span> 
                <br>
                <span>{{editData.sponsered==true?'Yes':'No'}}</span>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <label>
                Category
                <input type="text" disabled="true" class="form-control" [(ngModel)]="editData.category"/>
            </label>
        </div>
        </div>
    </div>
            <label style="margin-left: 4%;">
                Summary
                <textarea style="width: 80%;" class="form-control" [disabled]="!enableEdit">{{editData.summary}}</textarea>
            </label>
          </div>
          <div style="display: flex;padding: 3%;" *ngIf="!enableEdit">
            <!-- <button class="form-control" style="width: 30%;
            background: rgba(15, 15, 167, 0.566);
            margin-top: 3%;
            margin-bottom: 2%;
            color: white;" (click)="UpdateNews()">
                Update
            </button> -->
            <button style="width: 50%;
            color: white;
            background: red;" (click)="showDelete(editData)" class="form-control">
            <!-- <img src="../../assets/delete.png" (click)="showDelete(editData)" style="width:2%;cursor: pointer;position:relative;left:5%;margin-top:3%"/> -->
            <span>Delete</span>
        </button>
        <button class="form-control" style="width: 50%;
        color: white;
        background: rgb(83, 83, 240);
        margin-left: 5%;" (click)="enableEdit=!enableEdit">
            <!-- <img src="../../assets/edit.png"  style="width:2%;cursor: pointer;position:relative;left:5%;margin-top:3%"/> -->
            <span>Edit</span>
        </button>
        
          </div>
          <span style="color: red;font-size: medium;">{{createError}}</span>

          <div style="display: flex;padding: 3%;" *ngIf="enableEdit">
            <!-- <button class="form-control" style="width: 30%;
            background: rgba(15, 15, 167, 0.566);
            margin-top: 3%;
            margin-bottom: 2%;
            color: white;" (click)="UpdateNews()">
                Update
            </button> -->
            <button style="width: 50%;
            color: white;
            background: red;" (click)="enableEdit=!enableEdit" class="form-control">
            <!-- <img src="../../assets/delete.png" (click)="showDelete(editData)" style="width:2%;cursor: pointer;position:relative;left:5%;margin-top:3%"/> -->
            <span>Cancel</span>
        </button>
        <button class="form-control" style="width: 50%;
        color: white;
        background: rgb(83, 83, 240);
        margin-left: 5%;" (click)="UpdateNews('update')">
            <!-- <img src="../../assets/edit.png"  style="width:2%;cursor: pointer;position:relative;left:5%;margin-top:3%"/> -->
            <span>Submit</span>
        </button>
        
          </div>
        </div>
      
      </div>

      <div id="deleteModal" *ngIf="showDelete_" class="modal">
        <div class="modal-content-delete" style="margin-top: 10%;">
          <span class="close" (click)="closeModalDelete()">&times;</span>
          <h4 style="display: inline-block;">Are you sure you want to delete this!!?</h4>
          <div style="display: flex;justify-content: space-around;">
            <button class="form-control" style="width: 30%;
            background: rgba(244, 4, 4, 0.566);
            margin-top: 3%;
            margin-bottom: 2%;
            color: white;" (click)="DeleteNews()">
                Delete
            </button>
            <button class="form-control" style="width: 30%;
            background: rgba(243, 245, 249, 0.566);
            margin-top: 3%;
            margin-bottom: 2%;" (click)="closeModalDelete()">
                Cancel
            </button>
          </div>
        </div>
      
      </div>



      <div id="myModalcreate" *ngIf="showModalCreate" class="modal">
        <div class="modal-content">
          <span class="close" (click)="closeModalCreate()">&times;</span>
          <!-- <p>Some text in the Modal..</p> -->
          <div class="row">
        <div>
            <div>
            <label style="margin-left: 4%;">
                Caption</label>
                <input class="form-control" style="margin-left: 3%;width: 80%;"  [(ngModel)]="title"/>

            <br>
            <label>Select media Type</label>
            <select class="form-control" (change)="onChangeMediaType($event)">
                <option value="'">Please Select</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
            </select>
            <label  style="margin-left:3% ;">Upload Banner Image</label>
                <input *ngIf="mediaType=='image'"type="text" placeholder="Enter comma seperated values" class="form-control"  style="margin-left: 3%;width: 80%;"  multiple  (change)="OnChangeInput('image',$event)"/>
                <input *ngIf="mediaType=='video'" type="text" placeholder="Enter 1 video name that you uploaded on to s3" class="form-control" style="margin-left: 3%;width: 80%;"    (change)="OnChangeInput('video',$event)"/>
                                <!-- [(ngModel)]="editData.image_url" -->
                <span style="color: green;font-weight: bold;">{{imageupload_progress}}</span>
                <!-- <div *ngIf="editData.image_url!='' || editData.image_url==undefined" style="display: flex;width: min-content;flex-direction: row-reverse;position: relative;left: 29px;">
                    <span style="cursor: pointer;
                    position: relative;
                    left: 5%;
                    top: 6px;
                    
                    " (click)="removeImage()">X</span>
                <img [src]="editData.image_url" style="width: 300px;margin-top: 5%;" />
            </div> -->
            <br>
            <label>Select Community</label>
            <select class="form-control" (change)="onSelectCommunity($event)">
                <option value="">Please select</option>
                <option *ngFor="let com of communityData" value="{{com.community_id}}">{{com.community_name}}</option>
            </select>
            <br>
            <label>Select User</label>
            <select class="form-control" (change)="onSelectUser($event)">
                <option value="">Please Select</option>
                <option *ngFor="let user of usersData" value="{{user.id}}">{{user.name}}</option>
            </select>
                <!-- <img style="width: 20%;margin-left: 4%;" [src]="editData.image_url" class="form-control"/> -->
      
        </div>
        <div style="margin-top: 4%;
        margin-bottom: 3%;">
        <!-- <div style="    display: flex;
        justify-content: space-around;
        margin-left: 0;
        padding-left: 0;
        position: relative;
        margin-bottom: 3%;">
            <label>
                Sentiment   </label>
                <input  type="text" class="form-control" [(ngModel)]="editData.sentiment"/>
         
            &nbsp;&nbsp;&nbsp;&nbsp;
        </div> -->
        <!-- <label style="margin-left:3% ;">
            Sentiment</label>
            <select [(ngModel)]="editData.sentiment" style="width: 80%;
            margin-left: 2%;
            margin-top: 5px;" class="form-control">
                <option value="" selected>Please Select</option>
                <option value="positive">Positive</option>
                <option value="negative">Negative</option>
                <option value="neutral">Neutral</option>
            </select> -->
        <!-- <div style="display: flex;">
        <div style="width: 80%;
        margin-left: 3%;
        margin-top: 3%;">
            <label>
               <span style="font-weight: bold;">Is Sponsored?</span> 
                </label>
                <select [(ngModel)]="editData.sponsered" class="form-control">
                    <option value="" selected>Please Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
            <br>
            <div style="width: 80%;
            margin-left: 3%;
            margin-top: 3%;">

            <label>
                Category</label>

                <select [(ngModel)]="editData.category" class="form-control">
                    <option value="" selected>Please Select</option>
                    <option value="event">Event</option>
                    <option value="advertisement">Advertisement</option>
                    <option value="opinion">Opinion</option>
                </select>
        </div>
        </div> -->
    </div>
            <!-- <label style="margin-left: 4%;">
                Summary
                <textarea cols="95" rows="10" style="width: 80%;" class="form-control" [(ngModel)]="editData.summary">{{editData.summary}}</textarea>
            </label> -->
          </div>
          <div>
            <span style="color: red;font-size: medium;">{{createError}}</span>
            <button class="form-control" style="width: 30%;
            background: rgba(15, 15, 167, 0.566);
            margin-top: 3%;
            margin-bottom: 2%;
            color: white;" (click)="createPost()">
                Create
            </button>
          </div>
        </div>
      
      </div>