import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent {
  @Input() options:AnimationOptions={path:'/assets/pandas.json'};
  @Output() animationCreated = new EventEmitter();
  onAnimate(animationItem: AnimationItem): void {   
    console.log(animationItem);    
    this.animationCreated.emit(animationItem);  
  };
  
};

