<app-headers></app-headers>
<div style="margin-left: 5%;">
    <h3>Monitor</h3>
</div>
<button class="form-control" (click)="CreateNews()" style="background: rgba(24, 24, 180, 0.603);
color: white;
width: 15%;
float: right;">Create</button>

<table>
    <tr>
      <th>post</th>
      <th>Text</th>
      <th>Action</th>
    </tr>
    <tr *ngFor="let posts of postsdata ;let i=index">
      <td>
        <!-- <swiper-container *ngIf="posts.media_type=='image'" [pagination]="{ clickable: true }">
            <swiper-slide style="width: 100%;" *ngFor="let img of posts.media_url">
              <img  style="width: 50%;" [src]="Post_url+img" class="swiper-img-custom" alt="img"/>
              </swiper-slide>         
          </swiper-container> -->
          <div *ngFor="let img of posts.media_url">
            <img style="width: 500px;" [src]="Post_url+img"/>
          </div>
          <div *ngIf="posts.media_type=='video'">
            <video >
                <source [src]="Post_url+posts.media_url[0]" type="video/mp4" src="">
            </video>
          </div>
    </td>
    <td>
        <span>{{posts.text_}}</span>
    </td>
      <!-- <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">{{news.source_domain}}</td>
      <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''" style="width: 10%;"><a style="text-decoration: underline;color: blue;cursor: pointer;" [href]="news.url" target="_blank">{{news.url}}</a></td>
      <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">
        <img *ngIf="isToday(news.date_publish)" src="../../assets/rocket.png"/>
        {{news.date_publish | date}}</td>
      <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">{{news.summary}}</td>
      <td [ngClass]="news.url.split('/')[2]=='blockpanda.co'?'ownedbyus':''">  <img *ngIf="news.image_url!=''" alt="pandas" [src]="news.image_url" style="width:70%"/>
        <img *ngIf="news.image_url==''"  src="../../assets/pandas.png" style="width:70%"/>
    </td>
       -->
      <td style="display: flex;
      align-items: center;">
        <!-- <a  data-toggle="modal" data-target="#exampleModal" (click)="showEdit(news)"><img src="../../assets/fullscreen.png" style="width: 30%;cursor: pointer;margin-right: 3%;"/></a> -->
        <!-- <a [href]="appUrl+news.slug" target="_blank"><img src="../../assets/view.png" style="width: 50%;cursor: pointer;"/></a> -->
        <a style="width: min-content;" (click)="deletePost(posts)"><img style="width: 50px;" src="../../assets/delete.png"/></a>
        <a (click)="ForwardPost(posts)"><img src="../../assets/forward.png"/></a>
      </td>
    </tr>
    <!-- <pagination-controls style="position: relative;top:50px;display: table-caption;" (pageChange)="p = $event" nextLabel="" previousLabel=""></pagination-controls> -->
  </table>

  <div id="myModal" *ngIf="showmodalforward" class="modal">
    <div class="modal-content">
        <h3>Forward Post</h3>
      <span class="close" (click)="closeModal()">&times;</span>
      <!-- <select multiple="true" class="form-control" (change)="onSelectOptions($event)">
        <option value="">Please select</option>
        <option *ngFor="let com of communityData" value="{{com.community_id}}">{{com.community_name}}</option>
      </select> -->
     <div>
      <ng-multiselect-dropdown
      [placeholder]="'Select Communities'"
      [settings]="dropdownSettings"
      [data]="communityData"
      [(ngModel)]="selectedItems"
      (onSelect)="onItemSelect($event)"
      (onSelectAll)="onSelectAll($event)"
    >
    </ng-multiselect-dropdown>
    <br>
    <select class="form-control" (change)="onSelectUser($event)">
        <option value="">Please Select</option>
        <option *ngFor="let user of usersData" value="{{user.id}}">{{user.name}}</option>
    </select>
</div>
    <!-- <ng-multiselect-dropdown
    [placeholder]="'Select User'"
    [settings]="dropdownSettings"
    [data]="usersData"
    [(ngModel)]="selectedItems"
    (onSelect)="onItemSelect($event)"
    (onSelectAll)="onSelectAll($event)"
  >
  </ng-multiselect-dropdown> -->
    <button class="form-control primary"  style="width: 30%;margin-top:5%;margin-bottom: 5%;background-color: rgb(101, 101, 203);color:white" (click)="createForwardsRecord()">Forward</button>
    </div>
  
  </div>

  <div id="myModalcreate" *ngIf="showModalCreate" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModalCreate()">&times;</span>
      <!-- <p>Some text in the Modal..</p> -->
      <div class="row">
    <div>
        <div>
        <label style="margin-left: 4%;">
            Caption</label>
            <input class="form-control" style="margin-left: 3%;width: 80%;"  [(ngModel)]="title"/>

        <br>
        <label>Select media Type</label>
        <select class="form-control" (change)="onChangeMediaType($event)">
            <option value="'">Please Select</option>
            <option value="image">Image</option>
            <option value="video">Video</option>
        </select>
        <label  style="margin-left:3% ;" *ngIf="mediaType!=''">Upload Media</label>
            <!-- <input *ngIf="mediaType=='image'"type="text" placeholder="Enter comma seperated values" class="form-control"  style="margin-left: 3%;width: 80%;"  multiple  (change)="OnChangeInput('image',$event)"/> -->
            <input *ngIf="mediaType=='image'" type="file" class="form-control" multiple (change)="uploadFile($event,'image')"/>
            <input *ngIf="mediaType=='video'" type="file"  class="form-control" style="margin-left: 3%;width: 80%;"    (change)="onVideoUpload($event)"/>
            <span style="font-weight: bold;color:green" *ngIf="videoreadyProgress!=100 && videoreadyProgress!=0">Upload pecentage: {{videoreadyProgress}}%</span>
                            <!-- [(ngModel)]="editData.image_url" -->
            <!-- <span style="color: green;font-weight: bold;">{{imageupload_progress}}</span> -->
            <!-- <div *ngIf="editData.image_url!='' || editData.image_url==undefined" style="display: flex;width: min-content;flex-direction: row-reverse;position: relative;left: 29px;">
                <span style="cursor: pointer;
                position: relative;
                left: 5%;
                top: 6px;
                
                " (click)="removeImage()">X</span>
            <img [src]="editData.image_url" style="width: 300px;margin-top: 5%;" />
        </div> -->
        <br>
        <label>Select Community</label>
        <select class="form-control" (change)="onSelectCommunity($event)">
            <option value="">Please select</option>
            <option *ngFor="let com of communityData" value="{{com.community_id}}">{{com.community_name}}</option>
        </select>
        <br>
        <label>Select User</label>
        <select class="form-control" (change)="onSelectUser($event)">
            <option value="">Please Select</option>
            <option *ngFor="let user of usersData" value="{{user.id}}">{{user.name}}</option>
        </select>
            <!-- <img style="width: 20%;margin-left: 4%;" [src]="editData.image_url" class="form-control"/> -->
  
    </div>
    <div style="margin-top: 4%;
    margin-bottom: 3%;">
    <!-- <div style="    display: flex;
    justify-content: space-around;
    margin-left: 0;
    padding-left: 0;
    position: relative;
    margin-bottom: 3%;">
        <label>
            Sentiment   </label>
            <input  type="text" class="form-control" [(ngModel)]="editData.sentiment"/>
     
        &nbsp;&nbsp;&nbsp;&nbsp;
    </div> -->
    <!-- <label style="margin-left:3% ;">
        Sentiment</label>
        <select [(ngModel)]="editData.sentiment" style="width: 80%;
        margin-left: 2%;
        margin-top: 5px;" class="form-control">
            <option value="" selected>Please Select</option>
            <option value="positive">Positive</option>
            <option value="negative">Negative</option>
            <option value="neutral">Neutral</option>
        </select> -->
    <!-- <div style="display: flex;">
    <div style="width: 80%;
    margin-left: 3%;
    margin-top: 3%;">
        <label>
           <span style="font-weight: bold;">Is Sponsored?</span> 
            </label>
            <select [(ngModel)]="editData.sponsered" class="form-control">
                <option value="" selected>Please Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
        </div>
        <br>
        <div style="width: 80%;
        margin-left: 3%;
        margin-top: 3%;">

        <label>
            Category</label>

            <select [(ngModel)]="editData.category" class="form-control">
                <option value="" selected>Please Select</option>
                <option value="event">Event</option>
                <option value="advertisement">Advertisement</option>
                <option value="opinion">Opinion</option>
            </select>
    </div>
    </div> -->
</div>
        <!-- <label style="margin-left: 4%;">
            Summary
            <textarea cols="95" rows="10" style="width: 80%;" class="form-control" [(ngModel)]="editData.summary">{{editData.summary}}</textarea>
        </label> -->
      </div>
      <div>
        <span style="color: red;font-size: medium;">{{createError}}</span>
        <button class="form-control" style="width: 30%;
        background: rgba(15, 15, 167, 0.566);
        margin-top: 3%;
        margin-bottom: 2%;
        color: white;" (click)="createPost()">
            Create
        </button>
      </div>
    </div>
  
  </div>