import { Component,inject,OnInit } from '@angular/core';
import { RestapiService } from '../Services/restapi.service';
import { environment } from 'src/environments/environment';
import { Storage, ref, uploadBytesResumable,getDownloadURL } from '@angular/fire/storage';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }
export class NewsComponent implements OnInit {
  constructor(
    private restApi:RestapiService,
    private storage: Storage = inject(Storage),
    private cdr: ChangeDetectorRef

  ){

  }
  appUrl:any=environment.appUrl
  newsList:any=[]
  ngOnInit(): void {
    // this.getNews()
    this.getCommunityDetails()
    this.getUser()
  }
  offset:any=0;
  limit:any=100;
  p:any=0;
  getNews(){
    // this.loader=true
    this.restApi.getNews(this.offset,this.limit).subscribe((res)=>{
      // this.loader=false
      console.log(res)
      if(res){  
        this.newsList=res
      }else{
        this.newsList=[]
      }
    })
  }
  bannerImage:any=''
  imageupload_progress:any=''
  communityData:any
  getCommunityDetails(){
    this.restApi.getCommunity().subscribe((res:any)=>{
      console.log(res);
      if(res.status==200){
        this.communityData = JSON.parse(res.body)['data']
        console.log(this.communityData)
      }
    })
  }
  // onuploadImage(e:any){
  //   let file = e.target.files
  //   console.log(e,file)
  //   if (file){
  //     // const storage = getStorage();
  //     // const storageref = ref(storage,`images/` + file[0].name);
  //     // const uploadTask = uploadBytesResumable(storageref,file);
  //     for(let i=0;i<file.length;i++){
  //       const storageRef = ref(this.storage,file[i].name);
  //       const task = uploadBytesResumable(storageRef,file[i])
  //       task.on('state_changed',(snapshot)=>{
  //         const progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
  //         this.imageupload_progress=`Uploading the image ${i+1}`
  //         console.log('upload is '+progress+'% done');
  //         switch(snapshot.state){
  //           case 'paused':
  //             this.imageupload_progress='Image Upload paused'
  //             console.log('upload paused')
  //             break;
  //           case 'running':
  //             console.log('upload is running')
  //             break;
            
  //         }
  //       },
  //       (error)=>{
  //         this.imageupload_progress='Image upload failed call shashank now.'
  //       },
  //       ()=>{
  //         getDownloadURL(task.snapshot.ref).then((downloadUrl)=>{
  //           this.imageupload_progress='uploaded Image'
  //           setTimeout(()=>{
  //               this.imageupload_progress=''
  //               this.cdr.detectChanges()
  //           },1000)
  //           console.log('File uploaded! download URL is ',downloadUrl);
  //           this.editData.image_url.push(downloadUrl);
  //           this.bannerImage = downloadUrl
  //           this.cdr.detectChanges();
  
  //           console.log(this.editData)
  //         })
  //       }
  //       )
  //     }
  //     this.getCommunityDetails()
  //     this.getUser()
  //   }
  // }
  imageFiles:any
  imageFileSubmit:any=[]
  OnChangeFile(type:any,e:any){
    console.log(e)
    if(type=='image'){
    
      // else{
      // this.imageFiles.push(e.target.files[0]);
      console.log(e.target.files)
      this.imageFiles=Object(e.target.files).length?Array.from(e.target.files):e.target.files;
      console.log(this.imageFiles,'single file',this.imageFiles[0])
      // this.imageFiles.forEach((e:any) => {
      //     console.log(e)
      // });
      console.log(this.imageFiles)
      // this.imageFiles.forEach((ele:any) => {
        this.imageFiles.forEach((file: File) => {
          setTimeout(() => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const base64String = e.target.result;
              console.log(base64String);
              this.imageFileSubmit.push(base64String);
            };
            reader.readAsDataURL(file);
          }, 5000);
        });
  
        console.log(this.imageFileSubmit)
        // }
      // });
 
      this.image_added= this.imageFiles.length>0?true:false
    }if(type=='video' && !this.image_added){
      console.log(e)
      this.videoFile= e.target.files[0]
      console.log(this.videoFile)
      const video = document.createElement('video');
      video.preload = 'metadata';
      const reader = new FileReader();
      // console.log(this.videoFile.size/(1024*1024),'bytes')
      if(this.videoFile.size/(1024*1024)<26){
        reader.onload = (e) =>{
          console.log(e)
          const videoData = e.target?.result
          this.VideoSubmit = videoData
          // this.videoFormSubmit.append('video',this.videoFile);
          // console.log(this.videoFormSubmit)
          }
          let videoString = reader.readAsBinaryString(this.videoFile);
          
          // this.videoFiles.push(videoFile)
          this.videoFiles = this.videoFile
          console.log(this.videoSrc)
        }else{
         
        }
    
    }
  }
  upload_files:any
  OnChangeInput(type:any,e:any){
    if (type=='image'){
      this.upload_files = e.target.value.split(',')
      for (let i = 0; i < this.upload_files.length; i++) {
        const fileParts = this.upload_files[i].split('.');
        console.log(fileParts)
        if (fileParts.length >= 2 || ['.jpg', '.png', '.jpeg'].includes(fileParts[fileParts.length - 1])) {
            // Handle invalid file

        }else{
          console.log(this.upload_files)
          alert('invalid file names found')
          this.upload_files=[]
        }
    }
    }else if(type=='video'){
      this.upload_files = e.target.value
      for (let i = 0; i < this.upload_files.length; i++) {
        const fileParts = this.upload_files[i].split('.');
        if (fileParts.length < 2 || !['.mp4', '.avi', '.mov'].includes(fileParts[fileParts.length - 1])) {
            // Handle invalid video file
            alert('invalid video file format')
        }else{

        }
    }
    }
  }
  mediaType:any
  onChangeMediaType(e:any){
    this.mediaType = e.target.value
    console.log(e)
  }
  videoFiles:any
  videoSrc:any
  videoFile:any
  VideoSubmit:any
  image_added:any
  selectedCommunity:any
  onSelectCommunity(e:any){
    this.selectedCommunity = e.target.value
    console.log(e)
  }
  showModalEdit:boolean=false
  editData:any={
    id:'',
    title:'',
    sponsered:'',
    sentiment:'',
    category:'',
    image_url:''
  }
  domainType:any=''
  showEdit(data:any){
    this.showModalEdit=true
    console.log(data)
    this.editData = data;
  }

  searchByName:any='';
  filterOption:any='title'
  filterdate:any
  domain_names:any=[
    'blockchainreporter.net',
    'coindesk.com',
    'dlnews.com'
  ];
  closeModal(){
    this.showModalEdit=false
  }
  searchByName_(){
    if(this.searchByName!=undefined && this.searchByName!='' && this.searchByName!=null){
      this.newsList = this.newsList.filter((res:any)=>{
        return res.title.toLocaleLowerCase().match(this.searchByName.toLocaleLowerCase()) 
      })
    }else{
    this.getNews()
    }
  }
  createError:any=''
  enableEdit:boolean=false
  UpdateNews(type:any){
    console.log(this.editData)
    if(type=='create'){

    if(this.editData.title==undefined || this.editData.title=='' || this.editData.title==null){
      this.createError="Please Enter Title";
    }else if(this.editData.sentiment == undefined || this.editData.sentiment==null || this.editData.sentiment == ''){
      this.createError = "Please Choose Sentiment"
    }else if(this.editData.sponsered==undefined || this.editData.sponsered == null || this.editData.sponsered == ''){
      this.createError='Please Choose whether the post is sponsored or not'
    }else if(this.editData.category == undefined || this.editData.category == '' || this.editData.category == null){
      this.createError = "Please Choose Category"
    }else if(this.editData.summary == undefined || this.editData.summary == null || this.editData.summary==''){
      this.createError = "Please Summarize the news article in 60 words";
    }else{
      // if(type=='create'){

      
      this.createError=''
      let news_body={
        "title": this.editData.title,
        "summary": this.editData.summary,
        "category": this.editData.category,
        "sponsored": this.editData.sponsered=="true"?true:false,
        "sentiment": this.editData.sentiment,
        "language": "en",
        "maintext":"NULL",
        "image_url":this.editData.image_url,
        "title_page":this.editData.title,
        "authors":[]
    }
      this.restApi.createNews(news_body).subscribe((res:any)=>{
        console.log(res)
        this.showModalCreate=false
        alert('created news')
        this.getNews()
      })
    }
  
    }
    else if(type=='update'){
      if(this.editData.title==undefined || this.editData.title=='' || this.editData.title==null){
        this.createError="Please Enter Title";
      }else if(this.editData.summary == undefined || this.editData.summary == null || this.editData.summary==''){
        this.createError = "Please Summarize the news article in 60 words";
      }else{
      let news_body=  {
        "title": this.editData.title,
        "summary":  this.editData.summary,
        "image_url":this.editData.image_url,
        "id":this.editData.id
    }
    this.createError=''
    this.restApi.updateNews(news_body).subscribe((res)=>{
      console.log(res)
      this.showModalEdit=false
      this.enableEdit=false
      alert('Updated news')
      this.getNews()
    })
    }
  }
    // if(this.createError==''){

    // }

   
  }
  loader:boolean=false
backupArray:any=[]
  OnSelectDomain(){
    console.log(this.domainType)
    if(this.domainType!=''){
      // this.getNews()
      if(this.backupArray.length==0){
      this.backupArray = this.newsList
      }
    this.newsList=this.backupArray.filter((res:any)=>{
      return res.source_domain.toLocaleLowerCase().match(this.domainType.toLocaleLowerCase())
    })
    }else{
      this.getNews()
    }
  
  }

  isToday(datestring:string):boolean{
    const today = new Date();
    const date = new Date(datestring)
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
  }
  OnchangeDate(){
    console.log(this.filterdate)
    if (this.filterdate!=''){
    if (this.backupArray.length==0){
      this.backupArray = this.newsList
    }
    let selectedDate = new Date(this.filterdate)
    this.newsList = this.backupArray.filter((res:any)=>{
      return new Date(res.date_publish).toDateString() === selectedDate.toDateString()
    })
  }else{
    this.getNews()
  }
  }
  CreateNews(){
    this.showModalCreate=true
    this.editData={}
  }
  showModalCreate:boolean=false
  closeModalCreate(){
    this.showModalCreate=false
  }
  showDelete_:boolean=false
  delete_slug:any=''
  showDelete(news:any){
    this.showDelete_=true
    this.delete_slug = news["slug"]
  }
  closeModalDelete(){
    this.showDelete_=false
  }
  DeleteNews(){
    this.restApi.deletenews(this.delete_slug).subscribe((res:any)=>{
      console.log(res)
      this.closeModalDelete()
      this.showModalEdit=false
      alert('Deleted Successfully!')
      this.getNews()
    })
  }

  removeImage(){
    this.editData.image_url=''
    this.bannerImage=''
  }
  usersData:any
  selectedItemsUsers:any
onSelectUser(e:any){
  console.log(e)
  this.selectedItemsUsers = e.target.value

}

getUser(){
  this.restApi.getUsers().subscribe((res:any)=>{
    if(res.status==200){
      this.usersData = JSON.parse(res.body)['data']
    }
  })
}
title:any=''
createPost(){
  let createPostBody={
    'community_id':this.selectedCommunity,
    // 'uid':this.selectedItemsUsers,
    'uid':'d202b9a0-08b0-4f29-8462-092d3944b550',
    'media_type':this.mediaType=='image'?'image':this.mediaType=='video'?'video':'text',
    'text_':this.title,
    'media_alt':'',
    'media_url':this.upload_files
  }

  this.restApi.createPost(createPostBody).subscribe((res:any)=>{
    console.log(res)
  })
}
}
