import { Component,OnInit } from '@angular/core';
import { RestapiService } from '../Services/restapi.service';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {
  constructor(
    private restapi:RestapiService
  ){

  }
  ngOnInit(): void {
      this.getPosts()
      this.getCommunityDetails()
      this.getUser()
  }

  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'community_id',
    textField: 'community_name',
    allowSearchFilter: true
  };
  postsdata:any
  p:any=0
  Post_url:any=environment.postsUrl
  getPosts(){
   this.restapi.getPosts().subscribe((res:any)=>{
    console.log(res)
    if(res.status==200){
      this.postsdata = JSON.parse(res.body)["data"]
      console.log(this.postsdata)
    }
   })
}
showmodalforward:boolean=false;
closeModal(){
  this.showmodalforward=false
}
forwardPost:any
ForwardPost(post:any){
  this.forwardPost = post
  this.showmodalforward=true
  this.getCommunityDetails()
}
deletePost(post:any){
  console.log(post.id)
    this.restapi.deletePosts(post.id).subscribe((res:any)=>{
      console.log(res)
      if(res.status == 200){
        alert("Delete Successfully")
        this.getPosts();
      }else{  
        alert("Something went wrong!")
      }
    })
}
communityData:any
getCommunityDetails(){
  this.restapi.getCommunity().subscribe((res:any)=>{
    console.log(res);
    if(res.status==200){
      this.communityData = JSON.parse(res.body)['data']
      console.log(this.communityData)
    }
  })
}

usersData:any
selectedItemsUsers:any
showModalCreate:boolean=false
CreateNews(){
  this.showModalCreate=true
  // this.editData={}
}
closeModalCreate(){
  this.showModalCreate=false
}
selectedCommunity:any
mediaType:any
title:any
upload_files:any
createPost(){
  let postData = {
    text:this.title!=undefined?this.title:'',
    images: this.base64Strings,
    communityId:this.selectedCommunity,
    media_type:this.mediaType=='image'?'image':this.mediaType=='video'?'video':'text',
    media_url:"",
    media_alt:"",
    video:this.videoLink!=undefined?this.videoLink:'',
    videoExt : this.videoExt!=undefined?this.videoExt:'',
    userId:this.selectedItemsUsers,
  };
  // let createPostBody={
  //   'community_id':this.selectedCommunity,
  //   'uid':this.selectedItemsUsers,
  //   // 'uid':'d202b9a0-08b0-4f29-8462-092d3944b550',
  //   'media_type':this.mediaType=='image'?'image':this.mediaType=='video'?'video':'text',
  //   'text_':this.title,
  //   'media_alt':'',
  //   'media_url':this.upload_files
  // }
  console.log(postData)
  this.restapi.CreatePostCommunity(postData).subscribe((res:any)=>{
    console.log(res)
    if(res.Status){
      alert("created Successfully")
      this.getPosts()
    }else{
      alert("Something went wrong:"+res.message)
    }
  })
}
onChangeMediaType(e:any){
  this.mediaType = e.target.value
  console.log(e)
}
onSelectCommunity(e:any){
  this.selectedCommunity = e.target.value
  console.log(e)
}
OnChangeInput(type:any,e:any){
  if (type=='image'){
    this.upload_files = e.target.value.split(',')
    for (let i = 0; i < this.upload_files.length; i++) {
      const fileParts = this.upload_files[i].split('.');
      console.log(fileParts)
      if (fileParts.length >= 2 || ['.jpg', '.png', '.jpeg'].includes(fileParts[fileParts.length - 1])) {
          // Handle invalid file

      }else{
        console.log(this.upload_files)
        alert('invalid file names found')
        this.upload_files=[]
      }
  }
  }else if(type=='video'){
    this.upload_files = e.target.value
    for (let i = 0; i < this.upload_files.length; i++) {
      const fileParts = this.upload_files[i].split('.');
      if (fileParts.length < 2 || !['.mp4', '.avi', '.mov'].includes(fileParts[fileParts.length - 1])) {
          // Handle invalid video file
          alert('invalid video file format')
      }else{

      }
  }
  }
}
createError:any=''
getUser(){
  this.restapi.getUsers().subscribe((res:any)=>{
    if(res.status==200){
      this.usersData = JSON.parse(res.body)['data']
    }
  })
}
onSelectOptions(e:any){
  console.log(e)
}
selectedItems:any
onItemSelect(item: any) {
  console.log(item);
  console.log(this.selectedItems)
}
onSelectAll(items: any) {
  console.log(items);
}

createForwardsRecord(){
  console.log(this.forwardPost)
  let forwardPayload
  for(let i=0;i<this.selectedItems.length;i++){
    forwardPayload={
      'to_communityId':this.selectedItems[i].community_id,
      'from_userId':this.selectedItemsUsers,
      'media_type':this.forwardPost['media_type'],
      'text':this.forwardPost["text_"],
      'media_urls':this.forwardPost["media_url"],
    }
    this.restapi.createForwardUser(forwardPayload).subscribe((res:any)=>{
      console.log(res)
    })
    if(i==this.selectedItems.length){
      alert('Sent  Successfully')
    }
  }

}

onSelectUser(e:any){
  console.log(e)
  this.selectedItemsUsers = e.target.value

}
base64Strings:any=[]
uploadFile(e:any,type:any){
  if(type=='image'){
    console.log(e)
    const files = e.target.files;
    for(let i=0;i<files.length;i++){
      const reader = new FileReader();
      reader.onload = (e:any) =>{
        this.base64Strings.push(e.target.result)
        console.log(this.base64Strings)

      };
      reader.readAsDataURL(files[i])
    }
  }else if(type=='video'){

  }
}
videoFormSubmit:any=[]
videoFile:any
VideoSubmit:any
videoFiles:any
videoreadyProgress:any=0
videoLink:any
videoSrc:any
videoExt:any
onVideoUpload(e:any){
  try{
    console.log(e.target.files)
  let videoFiles = e.target.files[0]
  this.videoFormSubmit.push(e.target.files[0])
  console.log(videoFiles)
  // videoFormSubmi1t.append('files',this.videoFormSubmit)
  console.log(e)
  this.videoFile= e.target.files[0]
  console.log(this.videoFile)
  const video = document.createElement('video');
  video.preload = 'metadata';
  const reader = new FileReader();

  console.log(this.videoFile.size/(1024*1024),'bytes')
  if(this.videoFile.size/(1024*1024)<26){
    reader.onload = (e) =>{
      console.log(e)
      const videoData = e.target?.result
      this.VideoSubmit = videoData
      }
      this.videoFiles = this.videoFile
      // this.spinner=true
      // this.spinnerFile=true
      setTimeout(()=>{
        this.restapi.dummyUpload(this.videoFormSubmit).subscribe((res:any)=>{
          console.log(res,res.type)
          if(res.type == HttpEventType.UploadProgress){
            // console.log(res)
            console.log('loading')
            this.videoreadyProgress = Math.floor((res.loaded/res.total)*100)
            console.log(this.videoreadyProgress)
          }
          else
          {
            try{
              console.log(res)
              const file_url = res.body.link
              this.videoLink = file_url
              // this.spinner=false
              // this.spinnerFile=false
              this.videoSrc=URL.createObjectURL(this.videoFile)
              this.videoExt = res.body.name.split('.')[1]
              console.log(file_url)
            }catch(e){
              // console.log(e)
              
            }
            // this.spinner=false
            // console.log('done')
          }
          if(e.type == HttpEventType.Response){
            // this.spinner=false;
            // this.spinnerFile=false
            const file_url = e.body.link;
            console.log(file_url)
            this.videoLink = file_url
            this.videoSrc=URL.createObjectURL(this.videoFile)
            this.videoExt = res.body.name.split('.')[1]

          }
        })
      },0)
    
    }else{
      // this.ToastAndoid.showToast('Video size should be less than 20 MB').then((res)=>{
      //   res.present()
      // })
    }


}
  catch(err){
    console.log(err)
  }
  
}

}
