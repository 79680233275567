import { Component,OnInit } from '@angular/core';
import { RestapiService } from '../Services/restapi.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constructor(
    private apiService:RestapiService,
    private router:Router
  ){

  }
  ngOnInit(): void {
        this.getDetails()
  }
p:any=0
  getDetails(){
    this.apiService.getContactDetails().subscribe((res)=>{
      console.log(res)
      this.contactList = res
    })
  }
  contactList:any=[]
  onChangename(e:any){
    console.log(e.target.value)
    if(e.target.value!=''){
    this.contactList = this.contactList.filter((res:any)=>{
      return res['name'].toLocaleLowerCase().match(
        e.target.value
      )
    })
  }else{
    this.getDetails()
  }
  }
  logout(){
    localStorage.clear()
    sessionStorage.clear()
    this.router.navigate(['/login'])
  }
}
