export const environment = {
  firebase: {
    apiKey: "AIzaSyDYm6Z32gjlQRrI6Egn8UwOKInja4CGAtM",
    authDomain: "root-e1b6f.firebaseapp.com",
    projectId: "root-e1b6f",
    storageBucket: "root-e1b6f.appspot.com",
    messagingSenderId: "757180747464",
    appId: "1:757180747464:web:d049f354c94ad2781f1ffd",
    measurementId: "G-BRSDW38KLW"
  }  ,
  lambdaUrl:'https://6tmzwgphc3225fh7vreuawa2iq0jzzmb.lambda-url.ap-south-1.on.aws/',
  postsUrl:"https://matrixposts.s3.ap-south-1.amazonaws.com/",
  apiUrl:'https://mommyfoods.store/api/v1',
  lambdaCreate:"https://25cnc1o3b2.execute-api.us-east-1.amazonaws.com",
  lambdaDelete:'https://25cnc1o3b2.execute-api.us-east-1.amazonaws.com',
  appUrl:'https://www.blockpanda.co/'
};