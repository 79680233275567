
<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/1root_logo.jpeg" style="width: 10%;"/>
      </a>
        <button
        class="navbar-toggler"
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>
        <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        mdbCollapse
       
      >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="margin: 0;
        padding: 0;
        padding-right: 0;
        position: absolute;
        right: 10%;
        border: 1px solid grey;
        border-radius: 10px;">
          <!-- <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/dashboard">
              Home
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"routerLink="/monitor">
              Monitor
            </a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" routerLink="/login" tabindex="-1" aria-disabled="true"
              >Logout</a
            >
          </li>
        </ul>
        </div>
    </div>
  </nav>
