import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private afAuth:AngularFireAuth
  ) { }

  SignIn(email:any, password:any) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result);
      })
      // .catch((error) => {
      //   // window.alert(error.message);
      //   console.log(error)
      // });
  }
}
