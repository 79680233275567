<app-headers></app-headers>
<div class="cards-container">
    <div class="card" style="width: max-content;border-top:2px solid red">
        <div class="card-body">
        <h3>
            Total News Aggrigated Today
        </h3>
        <h4>
            100K
        </h4>
    </div>
    </div>
    <div class="card" style="width: 50%;">
        <div class="card-header">
            <h3>System Health</h3>
        </div>
        <div class="card-body">
            <table class="form-control">
                <thead>
                    <tr>
                        <th>
                            API Status
                        </th>
                        <th>
                            App Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="color:green">
                            Running
                        </td>
                        <td style="color:green">
                            Running
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


</div>
<div class="card" style="width: 90%;margin-top: 5%;margin-left: 5%;margin-bottom: 5%;">
    <div class="card-header">
        <h3>Scraper Health</h3>
    </div>
    <div class="card-body">
        <table class="form-control">
            <thead>
                <tr>
                    <th>
                        Confluent Status
                    </th>
                    <th>
                       Total Scrappers
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="color:green">
                        Running
                    </td>
                    <td style="color:blue">
                        5
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>