import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RestapiService {


  constructor(
    private ngFireStore:AngularFirestore,
    private http:HttpClient
  ) { }

  getContactDetails(){
    return this.ngFireStore.collection('contact').valueChanges()
  }

  getNews(offset:any,limit:any){
    return this.http.get(environment.lambdaUrl+'/dev/getnews?limit='+limit+'&offset='+offset)
  }

  createNews(news:any){
    let header=new HttpHeaders()
    header.append('Content-Type','application/json')
    header.append('Accept','*/*')
   return  this.http.post(environment.lambdaCreate+'/dev/create',news,{headers:header})
  }

  updateNews(news:any){
    let header = new HttpHeaders()
    header.append('Content-Type','application/json')
    header.append('Accept','*/*')
    return  this.http.post(environment.lambdaCreate+'/dev/edit',news,{headers:header})
  }
  deletenews(slug:any){
    return this.http.delete(environment.lambdaDelete+'/dev/delete?slug='+slug)
  }

  getPosts(){
    let header= {
      'content-Type': 'application/json',
  
      'Access-Control-Allow-Origin':'*'
    }
    let body= {
      "httpMethod":"GET",
      "api_parameters":{
      "api_name":"get_all_posts",
      "parameters":{
        
      }
    }
    }
    return this.http.post(environment.lambdaUrl,body,{headers:header})
  }

  deletePosts(postId:any){
    let header= {
      'content-Type': 'application/json',
  
      'Access-Control-Allow-Origin':'*'
    }
    let body=  {
      "httpMethod":"DELETE",
      "api_parameters":{
      "api_name":"delete_post",
      "parameters":{
        "post_id":postId
      }
    }
    }
    return this.http.post(environment.lambdaUrl,body,{headers:header})
  }

  getCommunity(){
    let header= {
      'content-Type': 'application/json',
  
      'Access-Control-Allow-Origin':'*'
    }
    let body= {
      "httpMethod":"GET",
      "api_parameters":{
      "api_name":"get_all_community_data",
      "parameters":{
        
      }
    }
    }
    return this.http.post(environment.lambdaUrl,body,{headers:header})
  }

  getUsers(){
    let header= {
      'content-Type': 'application/json',
  
      'Access-Control-Allow-Origin':'*'
    }
    let body= {
      "httpMethod":"GET",
      "api_parameters":{
      "api_name":"get_users",
      "parameters":{
        
      }
    }
    }
    return this.http.post(environment.lambdaUrl,body,{headers:header})
  }
  createForwardUser(body:any){
    return this.http.post(environment.apiUrl+'/feed/community/post/forward_post_to_community',body)
  }

  createPost(body:any){
    return this.http.post(environment.apiUrl+'/feed/community/post/create_admin_post',body)
  }

  dummyUpload(formData:File[]){
    console.log(formData)
    let newFormData:FormData = new FormData()
    newFormData.append('video',formData[0])
    console.log(newFormData)
    return this.http.post("https://file.io/",newFormData,{  
      reportProgress: true,  
      observe: 'events' ,
       
    })
  }

  CreatePostCommunity(data:any){
    let headers ={
      'Authorization':String(localStorage.getItem('token')),
      'content-Type':'*'
    }
    return this.http.post(environment.apiUrl+'/feed/community/create_community_post_admin',data,{headers})
  }

}



// {
//   "httpMethod":"DELETE",
//   "api_parameters":{
//   "api_name":"delete_post",
//   "parameters":{
//     "post_id":"f10f1635-8f9e-4b14-aaa1-9ba7ff15d6f7"
//   }
// }
// }