<div class="card custom-card">
    <div class="">
      <h1 class="title">Welcome Back</h1>
      <!-- <form class="form"> -->
          <div style="display: flex;">
      <div>
          <img src="../../assets/1root_logo.jpeg" style="width: 80%;"/>
      </div>
        <div class="row">
          <div class="col-lg-12">
            <span >Email address</span>
            <input type="email" [(ngModel)]="emailId" placeholder="Email" class="form-control" required>
            <span *ngIf="emailError!=''" style="color: red;">{{emailError}}</span>
          </div>
          <div class="col-lg-12">
            <span >Password</span>
            <input type="password" [(ngModel)]="password" placeholder="Password" class="form-control" required>
            <span *ngIf="passwordError!=''" style="color: red;">{{passwordError}}</span>

          </div>
          
          <button class="form-control" color="primary" style="height: max-content;width: 60%;position: relative;left: 20%;background: rgba(0, 0, 255, 0.555);color:white;top:5%" (click)="LoginUser()">Login</button>

        </div>
        <br>  
        <div>
        </div>
      </div>
        <!-- <div class="card-footer">
            <div>
              <a href="#" class="form-link">Forgot password?</a>
            </div>
          </div> -->
      <!-- </form> -->
    </div>
  </div>
